import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#00008f',
    },
    secondary: {
      main: '#00008f',
    },
    text: {
      primary: '#00008f',
      secondary: '#00008f',
    },
    error: {
      main: red.A400,
    },
  },
  listItemText:{
    fontSize:'0.7em',//Insert your required size
  }
});

export default theme;
