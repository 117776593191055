import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import Grid from '@mui/material/Grid';
import PropTypes from "prop-types";

function Header(props) {
  const {agent} = props;
  return (
    <React.Fragment>
      <Toolbar disableGutters={true} sx={{mb:3, mt: {xs:6, sm:6, md: 1}}}>
        <Grid container direction="row" alignItems="center" sx={{width:{ lg: '90%', md:'60%', sm:'17%', xs:'17%'}}}>
          <Grid item>
            <CardMedia
              component="img"
              alt="axa"
              sx={{
                width: {xs: '65px' ,sm:'80px'},
              }}
              image='/images/logo-axa.svg'/>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" sx={{ border:3, borderColor: '#00008f', m:1, width: {md: '45%', sm:'65%', xs:'100%'}}}>
          <Grid item sx={{p:1, textAlign: {xs : 'center' }, width:{sm: '10%', xs: '100%'}}}>
            <LocationOnIcon size="large" />
          </Grid>
          <Grid item sx={{textAlign: 'center', color: 'black', pl:{xs:0, sm:2}}}>
            <strong>{agent.firstname} {agent.lastname}</strong>
            <Typography>{agent.address}</Typography>
            <Typography>{agent.city}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" sx={{width: {md:'30%', sm:'40%'}}}>
          <Grid item sx={{p:1}}>
            <PhoneIcon size="large" />
          </Grid>
          <Grid item sx={{textAlign: 'center', p:1}}>
            {agent.phone}
          </Grid>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  agent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    orias_number: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;