import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import MainFeatured from './MainFeature';
import Header from './Header';
import Footer from './Footer';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ThemeProvider } from '@mui/material/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExtensionIcon from '@mui/icons-material/Extension';
import RadarIcon from '@mui/icons-material/Radar';
import EmailIcon from '@mui/icons-material/Email';
import CheckIcon from '@mui/icons-material/Check';
import RecommendIcon from '@mui/icons-material/Recommend';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Modal from '@mui/material/Modal';
import theme from './theme';

const agent = {
  title: 'Votre Agent Général AXA Prévoyance & Patrimoine Frédéric Magne',
  email: 'agencea2p.frederic.magne@axa.fr',
  phone: '06 26 88 69 80',
  address: '17 rue du cardinal lemoine',
  city: '75005 Paris',
  firstname: 'Frédéric',
  lastname: 'Magne',
  orias_number: '12068745'
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.traceweb.fr/">
        D.Djian
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
  boxShadow: 24
};

const mainFeatured = {
  title: 'Micro-Formation',
  present: 'Avocat: Quelle prévoyance en 2022 ?',
  description:
    "Savez-vous que l’assemblée générale de la Caisse Nationale des Barreaux Français (CNBF) a voté une réforme améliorant les garanties de prévoyance, en cas d’arrêt de travail, d’invalidité, décès.",
  image: '/images/blocs_business_desk_sante_prevoyance.jpeg',
  imageText: 'main image description',
  linkText: 'Je m\'inscris à la micro-formation',
};

export default function Layout() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <ThemeProvider theme={theme} >
      <CssBaseline/>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper'
          }}
        >
          <Container>
            <Header agent={agent} />
            <MainFeatured post={mainFeatured} />
            <Typography
              component="h2"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Qu'est qu'une micro formation ?
            </Typography>
            <Typography
              component="p"
              align="center"
              color="text.primary"

              sx={{marginBottom: 5}}
            >
              Démarrez dès demain, vous recevrez votre leçon directement par email chaque jour durant 5 jours.<br />
              Une leçon ne vous prendra que 3 min de temps de lecture.
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              color="text.primary"
              sx={{marginBottom: 5}}
            >
              <ExpandMore fontSize="inherit"/>
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              align="center"
              color="text.primary"
              sx={{marginBottom: 5}}
              gutterBottom
            >
              Ce que vous apprendrez lors de cette formation :
            </Typography>
            <Stack
              sx={{pt: {lg: 15}, marginBottom: 5, verticalAlign:'top', margin:'0 auto', display: {md: 'inline-block'} }}
              spacing={1}
              direction="row"
              justifyContent="center"
            >
              <List sx={{width: '100%', maxWidth : {sm: 500, md: 300, lg:500} }}>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText
                      primary="Pourquoi cette réforme ?"
                    />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Zoom sur les revalorisations des prestations des régimes de prévoyance des avocats"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Modernisation des prestations des régimes de prévoyance des avocats"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Simplification des conditions administratives des régimes de prévoyance des avocats"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Les impacts et conséquences pour vous"
                  />
                </ListItem>
              </List>
            </Stack>
            <Box component="div" sx={{marginBottom: 5, width: {xs: 400, sm: 540, md: 540}, height:{xs:700, sm:750, md:600}, margin:'0 auto', display: {md: 'inline-block'}}}>
              <iframe width="100%" height="100%" id="form"
                      src="https://52ee7bb6.sibforms.com/serve/MUIEAFLz_nCCzrlChDweBpaJfe4kE3267LfTJR_QYZC0K8oqraoKNd4L_1AC30hVfYzCnN3OC8MhqEXxTuaBCth6rpVYaoIZO4c2OwuWWB30I--sAPPz-YIJDceB_drGpNEG7dVHs8ghoCcnAuN8nITCLcHJrWC5w3n1gGs-De6rLL4l6s6zvVjPjmzQs2bWRtoJlFq1nUQWZH-V"
                      frameBorder="0" scrolling="no"></iframe>
            </Box>
            {/* End hero unit */}
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Card sx={{minHeight:200}}>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      <ExtensionIcon fontSize="large"/>
                    </Typography>
                    <Typography variant="h6" align="left" gutterBottom>
                      Un format ludique
                    </Typography>
                    <Typography variant="body2" color="text.secondary">Un moyen unique et efficace pour vous former au quotidien !</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card sx={{minHeight:200}}>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      <EmailIcon fontSize="large"/>
                    </Typography>
                    <Typography variant="h6" align="left" gutterBottom>
                      Un email par jour
                    </Typography>
                    <Typography variant="body2" color="text.secondary">Recevez chaque jours pendant 7 jours un email
                      pour approfondir vos connaissances sur Google Shopping et lancer des actions concrètes.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card sx={{minHeight:200}}>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      <RadarIcon fontSize="large" />
                    </Typography>
                    <Typography variant="h6" align="left" gutterBottom>
                      Allez à l'essentiel
                    </Typography>
                    <Typography variant="body2" color="text.secondary">3 minutes : c'est le temps de lecture maximum par email.</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Stack
                sx={{ pt: 4 , marginBottom: 5}}
                direction="row"
                spacing={2}
                justifyContent="center"
                gutterBottom
            >
              <Button variant="contained" startIcon={<PlayArrowIcon/>} href="#form">Je m'inscris à la micro-formation</Button>
            </Stack>

            <Typography
              component="h2"
              variant="h4"
              align="center"
              color="text.primary"
              sx={{marginBottom: 5}}
              gutterBottom
            >
              AXA Prévoyance & Patrimoine Parce que vous êtes unique, nous construisons, avec vous, les solutions cohérentes, appropriées à votre situation
            </Typography>

            <Grid container spacing={2} sx={{marginBottom: 15}}>
              <Grid item md={4} xs={12}>
                <Card sx={{minHeight:200}}>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      <LocationOnIcon fontSize="large"/>
                    </Typography>
                    <Typography variant="h6" align="left" gutterBottom>
                      Au plus proche de chez vous
                    </Typography>
                    <Typography variant="body2" color="text.secondary">1400 conseillers répartis dans toute la France</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card sx={{minHeight:200}}>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      <AllInclusiveIcon fontSize="large"/>
                    </Typography>
                    <Typography variant="h6" align="left" gutterBottom>
                      Un partenaire de proximité sur le long terme à chaque étape de votre vie
                    </Typography>
                    <Typography variant="body2" color="text.secondary">En moyenne, un conseiller vous accompagne pendant 12 ans</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Card sx={{minHeight:200}}>
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      <RecommendIcon fontSize="large" />
                    </Typography>
                    <Typography variant="h6" align="left" gutterBottom>
                      Un Savoir-faire reconnu
                    </Typography>
                    <Typography variant="body2" color="text.secondary">Plus de 60 ans d’expertise en protection sociale et patrimoniale des professions libérales, des entrepreneurs, des cadres et des entreprises.</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Footer agent={agent} />
          </Container>
        </Box>
      </main>
    </ThemeProvider>
  );
}