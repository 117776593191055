import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import EmailIcon from '@mui/icons-material/Email';
import DevicesIcon from '@mui/icons-material/Devices';
import MainFeatured from "./MainFeature";

function Footer(props) {
  const { agent } = props;

  return (
    <React.Fragment>
      <Typography component="h3" variant="h5" color="inherit" align="center">
        <strong>{agent.title}</strong>
      </Typography>
      <Typography component="h5" variant="h5" color="inherit" align="center" color="black" sx={{mb:3}}>
        {agent.address}, {agent.city}
      </Typography>
      <Typography>
        <strong>{agent.firstname.toUpperCase()} {agent.lastname.toUpperCase()}</strong><Typography color="black" display="inline"> N°ORIAS - https://www.orias.fr : {agent.orias_number}</Typography>
      </Typography>
      <Typography color="black" sx={{mb:4}}>
        Agent général exclusif d'assurance AXA Prévoyance & Patrimoine Mandataire exclusif en opérations de banque d'AXA Banque.
      </Typography>

      <Typography color="gray" sx={{mb:4}}>
        Coordonnées de l'Autorité de contrôle prudentiel et de résolution - 4 pl. de Budapest - CS 92459 - 75436 Paris CEDEX 09. Sociétés d'assurance
        mandantes AXA France Vie, AXA Assurances Vie Mutuelle, AXA France IARD, et AXA Assurances IARD Mutuelle. Le détail des procédures de recours et de
        réclamation et les coordonnées du service dédié sont disponibles sur le site <a href="https://www.axa.fr">https://www.axa.fr</a>. En matière d'assurance,
        en cas de non résolution d'un différend à l'issue du processus de réclamation, vous pouvez avoir recours au Médiateur, en vous adressant à l'association :
        La Médiation de l'Assurance, TSA 50110, 75441 Paris Cedex 09 - www.mediation-assurance.org
      </Typography>

      <Typography color="gray" sx={{mb:8}}>
        Les agents AXA sont régis par le <u>code des assurances</u>.
      </Typography>
      <Grid container spacing={2} sx={{marginBottom: 1, backgroundColor:'#00008f', color:'white'}}>
        <Grid item md={3} xs={12}>
          <Card sx={{minHeight:100, backgroundColor:'#00008f', color:'white'}}>
            <CardContent>
              <Typography variant="h6" display="inline-block">
                <PermIdentityIcon fontSize="large" sx={{verticalAlign:'middle'}}/>
              </Typography>
              <Typography variant="body" display="inline-block" sx={{verticalAlign:'middle', pl:5}}>
                Votre conseiller<br/>{agent.phone}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card sx={{minHeight:100, backgroundColor:'#00008f', color:'white'}}>
            <CardContent>
              <Typography variant="h6" display="inline-block">
                <EmailIcon fontSize="large" sx={{verticalAlign:'middle'}}/>
              </Typography>
              <Typography variant="body" display="inline-block" sx={{verticalAlign:'middle', pl:5}}>
                Email de votre agence<br />{agent.email}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={5} xs={12}>
          <Card sx={{minHeight:100, backgroundColor:'#00008f', color:'white'}}>
            <CardContent>
              <Typography variant="h6" display="inline-block">
                <DevicesIcon fontSize="large" sx={{verticalAlign:'middle'}}/>
              </Typography>
              <Typography variant="body" display="inline-block" sx={{verticalAlign:'middle', pl:5}}>
                Connectez-vous à votre Espace Client
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

Footer.propTypes = {
  agent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    orias_number: PropTypes.string.isRequired,
  }).isRequired,
};

export default Footer;